@use "../mixins/mixins-cards" as *;
@use "../mixins/mixins-responsive" as *;
@use "../functions/functions-fonts" as *;
@use "../mixins/mixins-webfonts-icons" as *;
@import "../components-style/modal-download-mobile-app";

$iconsHome: (
        "npicn-desktop": "\e919",
        "fa-map-pin": "\f276",
        "npicn-mobile-bar": "\e933",
        "npicn-calendar": "\e908",
        "npicn-file-pdf": "\e923",
        "npicn-fixe": "\e924",
        "npicn-wifi": "\e94f",
        "npicn-download-circle": "\e070",
        "npicn-upload-circle": "\e06d",
        "npicn-latency-circle": "\e06f",
        "npicn-infos-circle": "\e927",
        "npicn-celular": "\e909"
);
@include fontIconsList($iconsHome);

.home-page {
  h2 {
    font-size: 1.375rem;
  }

  header {
    min-height: 100vh;

    h2 {
      font-family: var(--font-text), sans-serif;
      font-size: 1.125rem;
      font-weight: normal;
      margin-top: 0.875rem;
    }

    .container-bg-header {
      bottom: pxToRem(55px);
      left: 0;
      position: absolute;
      right: 0;
      top: initial;
      z-index: auto;
    }

    .cls-1 {
      fill: var(--primary-3);
      stroke-width: 0;
    }

    &.container-web-app {
      background: var(--tertiary-3);
    }
  }

  .web-app {
    display: flex;
    justify-content: center;
    padding-top: 1.5rem;
    padding-left: 0;
    padding-right: 0;
  }

  .container-barometer {
    padding-top: 5.3rem;
    padding-bottom: 5.3rem;

    .container-content {
      margin: 0 auto;
      border-radius: 1.875rem;
      overflow: hidden;
      padding: 0;

      .container-bg-img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

    }

    .container-title-card {
      background: rgba(var(--black-rgb), 0.5);
      box-shadow: 0 pxToRem(4px) pxToRem(30px) rgba(var(--black-rgb), 0.1);
      -webkit-backdrop-filter: blur(14px);
      backdrop-filter: blur(14px);
      color: var(--white);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      text-align: center;
      padding: 1.25rem 0.5rem 0.5rem;

      .container-title-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
      }

      .container-img {
        display: inline-flex;
        align-items: center;
      }

      .flag-size {
        width: pxToRem(40px);
        height: pxToRem(30px);
        border-radius: pxToRem(5px);
      }

      .flag-rect {
        border-radius: 0.5rem;
      }

      .flag-square {
        border-radius: 50%;
      }

      h2 {
        margin-bottom: 0;
        margin-inline-start: 0.5rem;
      }

      p {
        flex-basis: 100%;
        font-family: var(--font-title), sans-serif;
        font-weight: bold;
        margin-top: 0.6rem;
        line-height: pxToRem(19px);
        padding-inline-start: 0.5rem;
        padding-inline-end: 0.5rem;
      }
    }

    .container-card-connection-type {
      padding-top: 2.4rem;
      padding-bottom: 0.525rem;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      position: relative;
      flex-direction: column;
      align-items: center;
    }
  }

  .card-barometer {
    @include card(var(--black), var(--white), true);
    margin-bottom: 1.875rem;
    padding: 1.25rem;
    font-family: var(--font-title), sans-serif;
    width: 265px;

    p {
      text-align: start;
    }

    .card-barometer-content {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;

      &:first-letter {
        text-transform: capitalize;
      }
    }

    .container-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.8rem;
    }

    .title {
      font-family: var(--font-title), sans-serif;
      font-size: 1.375rem;
      font-weight: bold;
      text-align: start;
      text-transform: capitalize;
    }

    .container-icon {
      font-size: 1.75rem;
      background: var(--tertiary);
      padding: 0.25rem .625rem;
      border-radius: 0.75rem;
      overflow: hidden;
      color: var(--dark);
    }

    .barometer-info {
      font-family: var(--font-text), sans-serif;
      color: var(--gray-medium);
      margin-bottom: 0.6rem;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &.location-info {
        margin-top: 1.25rem;
      }

      i {
        margin-inline-end: 0.25rem;
      }
    }

    .btn {
      max-width: 50%;
      margin: 0 auto;

      i {
        padding-bottom: 0.1rem;
      }
    }
  }

  .container-about {
    padding-top: 3rem;
    padding-bottom: 2.8rem;
    text-align: center;

    &.barometer-none {
      background: var(--tertiary);

      .card-test-type {
        background: var(--light);
      }
    }

    h2 {
      margin-bottom: 3rem;
    }

    .container-card-test-type {
      justify-content: center;
    }

    .card-test-type {
      @include card(var(--gray-dark-2), var(--tertiary), false, var(--light), 2.5rem, 80px);
      margin-bottom: 3rem;
      padding: 1.25rem 0.75rem 2.5rem;
      font-size: 0.875rem;
    }

    .container-card-logo {
      padding: 0.25rem .625rem;
      margin: 0 auto;

      &.icon-download i {
        color: var(--primary-5);
      }

      &.icon-upload i {
        color: var(--secondary-3);
      }

      &.icon-latency i {
        color: var(--gray-dark-3);
      }

      &.icon-infos i {
        color: var(--dark);
      }
    }

    strong:nth-of-type(1), .name {
      font-family: var(--font-title), sans-serif;
      font-size: 1.125rem;
      color: var(--dark);
      display: block;
      text-align: center;
    }

    strong:nth-of-type(1) {
      margin: 1.25rem 0 0;
    }

    .name strong {
      margin: 1.25rem 0 0.625rem;
    }
  }
}

@include respond-to(md) {
  .home-page {
    header {
      min-height: 60vh;
    }

    .web-app {
      padding-left: 15px;
      padding-right: 15px;
    }

    .container-barometer {
      .container-card-connection-type {
        flex-direction: row;
      }

      .container-title-card {
        .flag-size {
          width: pxToRem(80px);
          height: pxToRem(60px);
          border-radius: pxToRem(5px);
        }
      }
    }

    .card-barometer {
      margin-inline-end: 1.3rem;
      margin-inline-start: 1.3rem;
    }

    .container-about {
      .container-md {
        max-width: 90%;
      }

      .name strong {
        text-align: start;
      }

      .container-card-test-type {
        > div {
          margin-bottom: 3rem;
        }
      }

      .card-test-type {
        text-align: center;
        padding: 1.25rem 1.5rem 2.5rem;
        margin-bottom: 0;
        height: 100%;

        .container-card-logo {
          &.icon-infos {
            margin: initial;
          }
        }

        &.card-infos {
          text-align: start;
        }
      }
    }
  }
}

@include respond-to(lg) {
  .home-page {
    header {
      min-height: 90vh;
    }

    .container-barometer {
      .container-title-card {
        justify-content: flex-start;
        text-align: start;
        padding-bottom: 1.875rem;
        padding-top: 1.875rem;
        padding-inline-start: 1.15rem;
        flex-wrap: nowrap;

        .flag-square {
          border-radius: 0.5rem;
        }

        .container-title-content {
          display: initial;
        }

        h2 {
          margin-inline-start: 0;
          margin-bottom: 0.2rem;
          font-size: 1.875rem;
        }

        p {
          padding: 0;
          margin-top: 0;
          font-size: 1.25rem;
        }

        .container-img {
          flex-basis: pxToRem(80px);
          height: 100%;
          width: 100%;
          overflow: hidden;
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        .container-title-content {
          padding-inline-start: 0.875rem;
        }
      }
    }
  }
}

@include respond-to(xl) {
  .home-page {
    header {
      h2 {
        margin-top: 0;
      }
    }

    .container-barometer {

      .container-title-card {
        padding-inline-start: 1.5rem;
      }

      .container-md {
        max-width: 70%;
      }
    }

    .container-about {
      .container-md {
        max-width: 1200px;

        > div {
          margin: 0 auto;
        }
      }
    }
  }
}

@include respond-to(xxl) {
  .home-page {
    .container-barometer {
      .container-md {
        max-width: 1200px;
      }
    }
  }
}