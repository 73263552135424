@import "../functions/functions-fonts";
@import "../mixins/mixins-buttons";


.modal {
  &.modal-download-mobile-app {
    margin-top: 0;
  }

  &.modal-slide-up {
    .modal-dialog {
      transition: transform 0.3s linear;
    }
  }
}

.modal-download-mobile-app {
  z-index: 9999;

  .modal-dialog {
    &.modal-dialog-centered {
      margin: 0;
      min-height: 100%;
    }

    .modal-content {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      display: block;
      border-radius: 0;

      .modal-header {
        border-radius: 0;
        background: var(--light);
        padding: pxToRem(14px) pxToRem(13px) pxToRem(4px) 1.125rem;

        .close {
          color: var(--gray-light);
          font-size: pxToRem(15px);
          text-shadow: initial;
          font-weight: 700;
        }
      }

      .modal-body {
        border-radius: 0;
        background: var(--light);
        padding: 0 pxToRem(11.5px) 2rem pxToRem(11.5px);
      }
    }
  }

  .modal-dialog {
    max-width: 510px;
  }

  .modal-download-app {
    .modal-dl-app-title {
      font-family: var(--font-title), sans-serif;
      font-size: pxToRem(19px);
      color: var(--dark);
      font-style: normal;
      font-weight: 700;
      text-align: center;
      margin-bottom: pxToRem(22px);
      padding-inline-end: pxToRem(10px);
      padding-inline-start: pxToRem(10px);
    }

    .modal-dl-app-content {
      font-family: var(--font-text), sans-serif;
      font-size: pxToRem(13px);
      color: var(--gray-medium);
      font-style: normal;
      font-weight: 400;
      text-align: center;
      margin-bottom: pxToRem(22px);
      padding-inline-end: pxToRem(10px);
      padding-inline-start: pxToRem(10px);
    }

    .functioning-images {
      display: flex;
      flex-direction: row;
      padding-bottom: pxToRem(15px);
      justify-content: center;

      img {
        padding-inline-start: pxToRem(5px);
        padding-inline-end: pxToRem(5px);
      }
    }

    .btn-lg-default-primary-4 {
      @include button(0.875rem, var(--primary), var(--light), var(--primary), var(--light), var(--primary), var(--light), var(--primary), var(--light));
      border: none;
      margin-bottom: pxToRem(22px);
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      margin-inline-end: pxToRem(5px);
      margin-inline-start: pxToRem(5px);
      line-height: 16px;
      font-weight: 700;
    }

    .later-btn {
      font-family: var(--font-text), sans-serif;
      font-size: pxToRem(14px);
      font-weight: 700;
      color: var(--gray-medium);
      border: none;
    }

    button {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
}

@media only screen and (orientation: landscape) {
  .modal {
    &.modal-download-mobile-app {
      max-width: 100%;

      .modal-dialog {
        .modal-content {
          .modal-body {
            padding-bottom: 1rem;
          }
        }
      }

      .modal-download-app {
        .modal-dl-app-title {
          max-width: pxToRem(460px);
          font-size: pxToRem(17px);
          margin: auto auto pxToRem(10px) auto;
          padding-inline-end: 0;
          padding-inline-start: 0;
        }

        .modal-dl-app-content {
          max-width: pxToRem(460px);
          font-size: pxToRem(12px);
          margin: auto auto pxToRem(11px) auto;
        }

        .functioning-images {
          margin: auto;
          max-width: pxToRem(140px);
        }

        .btn-lg-default-primary-4 {
          max-width: pxToRem(460px);
          margin: auto auto pxToRem(13px) auto;
        }
      }
    }
  }
}