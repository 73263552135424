@mixin card($color, $bg, $shadow: false, $bgLogo : var(--light), $fontSizeIcon: 0.875rem, $sizeIcon: 50px, $colorIcon: var(--black)) {
  background: $bg;
  border-radius: 0.625rem;
  width: 100%;
  font-size: 0.875rem;
  text-align: center;
  color: $color;
  @if $shadow {
    box-shadow: 0 8px 10px rgb(0 0 0 / 16%);
  }

  .container-card-logo {
    background: $bgLogo;
    color: $colorIcon;
    font-size: $fontSizeIcon;
    width: $sizeIcon;
    height: $sizeIcon;
    border-radius: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}