@use "sass:map";
@import "variables";

@mixin respond-to($breakpoint, $mobile-first: true) {
  $breakpoint-value: map-get($breakpoints, $breakpoint);

  @if $mobile-first {
    @media (min-width: #{$breakpoint-value}) {
      @content;
    }
  } @else {
    @media (max-width: #{$breakpoint-value - 1}) {
      @content;
    }
  }
}