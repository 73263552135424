@mixin button($fontSize: 0.875rem, $bgColor: none, $color: var(--primary), $bgHover: var(--primary), $colorHover: var(--tertiary), $bgActive: var(--primary), $colorActive: var(--tertiary), $bgFocus: var(--white), $colorFocus: var(--primary), $bgDisabled: none, $colorDisabled: var(--gray-medium)) {
  cursor: pointer;
  color: $color;
  background: $bgColor;
  border-radius: 0.5rem;
  border: 1px solid $color;
  text-align: center;
  font-size: $fontSize;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    margin-inline-start: 0.5rem;
    font-size: 1rem;


    &:before {
      margin: 0;
    }
  }
  &:hover {
    color: $colorHover;
    background-color: $bgHover;
    box-shadow: none;
  }
  &.active {
    color: $colorActive;
    background-color: $bgActive;
    box-shadow: none;
  }
  &:focus {
    color: $colorFocus;
    background-color: $bgFocus;
    box-shadow: none;
  }
  &:disabled {
    color: $colorDisabled;
    background-color: $bgDisabled;
    box-shadow: none;

    &:hover {
      color: $colorDisabled;
      background-color: $bgColor;
      box-shadow: none;
    }
  }
}